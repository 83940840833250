$theme-colors: (
  "secondary" : #a64dff,
  "gray-light":#e6e6e6,
  "task-passed": #3cd11e,
  "task-in-progress": #ffb366,
  "task-not-tried": #bfbfbf,
  "green-light" : #80ff80
);

@import '../../node_modules/bootstrap/scss/bootstrap.scss';
