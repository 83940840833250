.App {
  text-align: center;
}

.AppHeader{
  min-height: 3em;
}

.AppHeader label{
  display: block;
  color: white
}

#qouteBlock{
  width: max-content;
  max-width: 100%;
}

.AppHeader label#headerQuote{
  text-align: left;
  font-family: 'Courier New', 'Arial Narrow', Arial, sans-serif;
  font-style: italic;
  font-size: medium;
  margin-bottom: 0;
}

.AppHeader label#headerQuoteAuthor{
  text-align: right;
  font-family: 'Courier New', 'Arial Narrow', Arial, sans-serif;
  font-size: medium;
  margin-top: 0;
}

.AppHeader label#appName{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: large;
  margin-bottom: 0;
}

.AppHeader label#appDesc{
  margin-top: 0;  
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: smaller;
}

.AppHeader label#username{
  font-size: 1.3em;
}

/*
  Руководство пользователя
*/

.HelpTopicHeader{
  font-size: 1.3em;
}

/*
  Логин
*/

#loginRow{
  min-height: 800px;
}

#loginForm{
  margin-top: 5em;
}

/*
  Footer
*/
.AppFooter{
  min-height: 2em;
  font-size: 14px;
  vertical-align: middle;
}